import React, {useEffect, useState} from 'react';
import {graphql, navigate} from 'gatsby';
import {Redirect} from '@reach/router';
import styled from 'styled-components';
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import PageWidth from '../../components/PageWidth';
import {Layout} from '../../components/Layout';
import SEO from '../../components/SEO';
import {Button} from '../../components/Button';
import {PageWrapper, Box} from '../../styles/page';
import {STUDIO_SUBSCRIBED} from '../../constants/commands';
import Typography from '../../constants/typography';
import {getPageData, getBuildEnvFromData} from '../../utils';
import {sendAnalyticsEvent} from '../../utils/countly';
import {useAuth} from '../../services/auth';
import {getQueryParameter} from '../../utils';
import {PageProps} from '../../types';
import {SubscriptionSuccessPageQuery} from './__generated__/SubscriptionSuccessPageQuery';

type SubscriptionSuccessProps = PageProps<SubscriptionSuccessPageQuery>;

const SubscriptionSuccessPage = (props: SubscriptionSuccessProps) => {
  const {user} = useAuth();
  const [isSubscribed, setSubscribed] = useState(false);

  const handleRedirect = () => {
    if (isMobile === false) {
      window.location.assign(STUDIO_SUBSCRIBED);
    }
  };

  useEffect(() => {
    setSubscribed(getQueryParameter('subscribed') ? true : false);
  }, []);

  React.useEffect(() => {
    if (isSubscribed) {
      return;
    }

    const method = getQueryParameter('method');
    sendAnalyticsEvent('subscription-success', method ? {method} : {});

    const timer = setTimeout(handleRedirect, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isSubscribed]);

  useEffect(() => {
    if (!user.id) {
      navigate('/subscription/plans');
    }
  }, [user]);

  const data = getPageData(props.data);

  let title = data.pageTitle;

  if (typeof document !== 'undefined') {
    title = isSubscribed ? data.alreadySubscribedTitle : data.pageTitle;
  }

  return (
    <Layout>
      <SEO title={title} />
      <PageWrapper grey>
        <PageWidth>
          <Heading>{title}</Heading>
          <InputWrapper>
            <BrowserView>
              <Para>{data.desktopMessage}</Para>
              <Button onClick={handleRedirect}>{data.openButton}</Button>
            </BrowserView>
            <MobileView>
              <Para>{data.mobileMessage}</Para>
            </MobileView>
          </InputWrapper>
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query SubscriptionSuccessPageQuery {
    en: allContentfulAmpifyWebSubscriptionSuccess(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          pageTitle
          desktopMessage
          mobileMessage
          openButton
          alreadySubscribedTitle
        }
      }
    }
    site {
      siteMetadata {
        buildEnv
      }
    }
  }
`;

const Heading = styled.h2`
  font-size: ${Typography.heading.desktop.fontSize};
  line-height: ${Typography.heading.desktop.lineHeight};
`;

const Para = styled.p`
  line-height: ${Typography.body.desktop.lineHeight};
  padding-bottom: 1rem;
`;

const InputWrapper = styled(Box)`
  margin-top: 2em;
  margin-bottom: 40vh;
  font-size: ${Typography.body.desktop.fontSize};
`;

export default SubscriptionSuccessPage;
