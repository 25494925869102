export function getQueryParameter(key: string): string {
  const query = window.location.search
    .slice(1)
    .split('&')
    .reduce((acc: {[key: string]: string}, param: string) => {
      const [k, value] = param.split('=');
      acc[k as string] = value;
      return acc;
    }, {});
  return query[key];
}
